import React from 'react'
import { Layout, Stack, Main } from '@layout'
import Seo from '@widgets/Seo'

const AccountServices = props => (
    <Layout {...props}>
        <Seo title='Account Services' />
        <Stack>
            <Main>
                <iframe src="https://home.tracfone.com/account-services" width="100%"  className="cm-content-frame"></iframe>
            </Main>
        </Stack>
    </Layout>
)

export default AccountServices
